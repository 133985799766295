import React from "react";
import { Link } from "gatsby";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="bg-steel-600 py-10 md:py-20 px-5 text-white text-center font-sans flex flex-col gap-y-3">
        <div>© {year} 鈴木正治税理士事務所</div>
        <Link to="/privacy" className="hover:text-steel-200">
          プライバシーポリシー
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
