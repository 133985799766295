import React from "react";
import { Link } from "gatsby";
import Logo from "../images/svg/logo.svg";
import { PhoneIcon } from "@heroicons/react/solid";

const Header = () => {
  return (
    <header className="text-gray-600">
      <div className="container mx-auto p-3 md:p-5 md:flex md:justify-between md:items-center">
        <div className="mb-3 md:mb-0">
          <Link to="/">
            <Logo className="w-2/3 md:w-full mx-auto mb-1" />
            <div className="text-base md:text-lg tracking-widest text-center md:text-left">
              群馬前橋 | 鈴木正治税理士事務所
            </div>
            <div className="text-xs md:text-sm tracking-tight md:tracking-tighter text-center md:text-left">
              関東経済産業局・財務局認定 経営革新等支援機関
            </div>
          </Link>
        </div>
        <div className="flex flex-col space-y-2 font-sans font-medium text-base md:text-lg">
          <a href="tel:0272434881">
            <div className="flex flex-row justify-center text-steel-500 font-tel text-2xl items-center">
              <PhoneIcon className="w-6 h-6 mr-1" />
              027-243-4881
            </div>
          </a>
          <div className="text-gray-500 border-gray-500 border-t-2 border-b-2 text-center py-1 tracking-widest">
            初回相談無料
          </div>
          <Link
            to="/contact"
            className="rounded-sm bg-steel-500 hover:bg-steel-400 text-white px-3 py-2 text-center tracking-wider"
          >
            お問い合わせ
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
